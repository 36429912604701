<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";

export default {
    page: {
        title: "Profile",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Profile",
            items: [
                {
                    text: "Profile",
                    active: true,
                },
            ],
            user_id: localStorage.getItem("session_user_id"),
            user_username: null,
            user_name: null,
            user_status: null,
            user_created_at: null,
            user_updated_at: null,
            user_last_login: null,
            user_role_name: null,
            photo_default: null,
            photo_full: null,

            // variable modal
            form_data: false,
            form_photo: false,

            // Catch Error Axios
            axiosCatchErrorProfile: null,
            axiosCatchErrorPhoto: null,

            // variabel edit data
            edit_username: null,
            edit_name: null,
            edit_password: null,
            edit_password_confirm: null,
            edit_photo: null,
            user_sso : JSON.parse(localStorage.getItem('sso_user')),
            user_sso_detail : JSON.parse(localStorage.getItem('sso_user_detail')),
        };
    },
    mounted() {
        this.DetailDataProfile();
    },
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        },
        show_modal_data() {
            let self = this;
            self.form_data = true;
        },
        show_modal_photo() {
            let self = this;
            self.form_photo = true;
        },
        DetailDataProfile() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });

            // get data user
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users" + "/" + self.user_id,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var data_edit = response_data_fix;
                        self.user_username = data_edit.username;
                        self.user_name = data_edit.name;
                        self.user_status = data_edit.status;
                        self.user_created_at = data_edit.created_at;
                        self.user_updated_at = data_edit.updated_at;
                        self.user_last_login = data_edit.last_login;
                        self.user_role_name = data_edit.name_role;
                        self.photo_default = data_edit.path_photo;
                        self.photo_full = process.env.VUE_APP_BACKEND_URL + data_edit.path_photo;
                        // self.selected_role = JSON.parse(data_edit.selected_role);

                        self.edit_username = data_edit.username;
                        self.edit_name = data_edit.name;
                        // self.user_sso = data_edit.json_user;

                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        StoreDataProfile() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users/" + self.user_id + "/profile",
                data: {
                    id: self.user_id,
                    username: self.edit_username,
                    password: self.edit_password,
                    password_confirm: self.edit_password_confirm,
                    name: self.edit_name,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan dimuat ulang.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            location.reload();
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchErrorProfile = error.response.data.data;
                    Swal.close();
                });
        },
        StoreDataPhoto() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users/" + self.user_id + "/photo",
                data: {
                    id: self.user_id,
                    path_photo: self.edit_photo,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan dimuat ulang.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            location.reload();
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchErrorProfile = error.response.data.data;
                    Swal.close();
                });
        },
        inputFilePhoto() {
            let self = this;
            if ($("#formPhoto")[0].files[0]) {
                if ($("#formPhoto")[0].files[0].size < 2242880) {
                    $("#uploadLoading").html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#formPhoto")[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        console.log(response.data.data.path_file);
                        var path_file = response.data.data.path_file;
                        urlres += path_file;
                        $("#uploadLoading").html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>');
                        self.edit_photo = path_file;
                    });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <!-- <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <h4 class="card-title mb-4">Foto Profil Akun Anda</h4>
                            </div>
                            <div class="col-md-6">
                                <div class="text-end">
                                    <button type="button" class="btn btn-success btn-sm" title="Ubah Foto Profil" v-on:click="show_modal_photo">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <center>
                                    <div v-if="photo_default == null">
                                        <div class="position-relative mb-2">
                                            <img src="@/assets/images/users/avatar-9.png" alt="Foto Profil Akun" class="img-thumbnail" style="width: 50%" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="position-relative mb-2">
                                            <img v-bind:src="photo_full" alt="Foto Profil Akun" class="img-thumbnail" style="width: 50%" />
                                        </div>
                                    </div>

                                    <h5 class="font-size-15 text-truncate">
                                        {{ user_name }}
                                    </h5>
                                    <p class="text-muted mb-0 text-truncate">
                                        {{ user_role_name }}
                                    </p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <h4 class="card-title mb-4">Data Profil Akun Anda</h4>
                            </div>
                            <div class="col-md-6">
                                <div class="text-end">
                                    <!-- <button type="button" class="btn btn-success btn-sm" title="Ubah Data Profil" v-on:click="show_modal_data">
                                        <i class="fas fa-edit"></i>
                                    </button> -->
                                </div>
                            </div>
                            <div class="col-md-12">
                                <!-- <p class="text-muted mb-4">
                  Hi  {{ user_username }}, di halaman ini anda dapat mengubah profil akun
                  anda.
                </p> -->
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap mb-0">
                                        <tbody>
                                            <!-- <tr>
                                                <th scope="row" style="width: 20%">Username</th>
                                                <td>{{ user_username }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Nama</th>
                                                <td>{{ user_name }}</td>
                                            </tr> -->
                                            <tr>
                                                <td style="width: 150px">Identity Number</td>
                                                <td>{{ user_sso_detail[0]?.IdentityNumber }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Name</td>
                                                <td>{{ user_sso_detail[0]?.Name }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Birth Place</td>
                                                <td>{{ user_sso_detail[0]?.BirthPlace }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Birth Date</td>
                                                <td>{{ user_sso_detail[0]?.BirthDate }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Gender</td>
                                                <td>{{ user_sso_detail[0]?.Gender }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Nationality</td>
                                                <td>{{ user_sso_detail[0]?.Nationality }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Address</td>
                                                <td>{{ user_sso_detail[0]?.Address }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Address Postal Code</td>
                                                <td>{{ user_sso_detail[0]?.AddressPostalCode }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Phone</td>
                                                <td>{{ user_sso_detail[0]?.Phone }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Email</td>
                                                <td>{{ user_sso_detail[0]?.Email }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Education Level</td>
                                                <td>{{ user_sso_detail[0]?.EducationLevel }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Company</td>
                                                <td>{{ user_sso_detail[0]?.Company }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Position Name</td>
                                                <td>{{ user_sso_detail[0]?.PositionName }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Company Address</td>
                                                <td>{{ user_sso_detail[0]?.CompanyAddress }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Company Postal Code</td>
                                                <td>{{ user_sso_detail[0]?.CompanyPostalCode }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 150px">Company Email</td>
                                                <td>{{ user_sso_detail[0]?.CompanyEmail }}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>UserId</td>
                                                <td>{{ user_sso?.UserId }}</td>
                                            </tr>
                                            <tr>
                                                <td>PositionId</td>
                                                <td>{{ user_sso?.PositionId }}</td>
                                            </tr>
                                            <tr>
                                                <td>OrganizationId</td>
                                                <td>{{ user_sso?.OrganizationId }}</td>
                                            </tr>
                                            <tr>
                                                <td>RoleId</td>
                                                <td>{{ user_sso?.RoleId }}</td>
                                            </tr>
                                            <tr>
                                                <td>UserName</td>
                                                <td>{{ user_username }}</td>
                                            </tr>
                                            <tr>
                                                <td>RoleName</td>
                                                <td>{{ user_sso?.RoleName }}</td>
                                            </tr>
                                            <tr>
                                                <td>PositionName</td>
                                                <td>{{ user_sso?.PositionName }}</td>
                                            </tr>
                                            <tr>
                                                <td>BandCode</td>
                                                <td>{{ user_sso?.BandCode }}</td>
                                            </tr>
                                            <tr>
                                                <td>UnitCode</td>
                                                <td>{{ user_sso?.UnitCode }}</td>
                                            </tr>
                                            <tr>
                                                <td>UnitName</td>
                                                <td>{{ user_sso?.UnitName }}</td>
                                            </tr>
                                            <tr>
                                                <td>Grade</td>
                                                <td>{{ user_sso?.Grade }}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>{{ user_sso?.Email }}</td>
                                            </tr>
                                            <tr>
                                                <td>ApplicationCode</td>
                                                <td>{{ user_sso?.ApplicationCode }}</td>
                                            </tr>
                                            <tr>
                                                <td>NewNIK</td>
                                                <td>{{ user_sso?.NewNIK }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status Akun</th>
                                                <td>
                                                    <div v-if="user_status == 'ENABLE'">
                                                        <div class="badge badge-pill badge-soft-success font-size-12">ENABLE</div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="badge badge-pill badge-soft-danger font-size-12">DISABLE</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Terakhir Login ke Aplikasi</th>
                                                <td>{{ fullDateTimeFormat(user_last_login) }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Waktu Pembuatan Akun</th>
                                                <td>{{ fullDateTimeFormat(user_created_at) }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Waktu Perubahan Data Terakhir</th>
                                                <td>{{ fullDateTimeFormat(user_updated_at) }}</td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
    </Layout>

    <b-modal v-model="form_data" id="modal-lg" size="lg" title="Ubah Data Profil Akun" title-class="font-18" hide-footer>
        <b-form class="p-2" @submit.prevent="StoreDataProfile">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="axiosCatchErrorProfile">
                        <div class="alert alert-danger" v-if="typeof axiosCatchErrorProfile === 'string' || axiosCatchErrorProfile instanceof String">
                            {{ axiosCatchErrorProfile }}
                        </div>
                        <div class="alert alert-danger" role="alert" v-else>
                            <div v-for="(errorArray, idx) in axiosCatchErrorProfile" :key="idx">
                                <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                    <span class="text-danger">{{ allErrors[0] }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <b-form-group class="mb-3" label="Username" label-for="formrow-username-input">
                        <b-form-input id="formrow-username-input" placeholder="Masukkan Username..." type="text" v-model="edit_username"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-12">
                    <b-form-group class="mb-3" label="Password" label-for="formrow-password-input">
                        <b-form-input id="formrow-password-input" placeholder="Masukkan Password..." type="password" v-model="edit_password"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-12">
                    <b-form-group class="mb-3" label="Konfirmasi Password" label-for="formrow-konfirmasi-password-input">
                        <b-form-input id="formrow-konfirmasi-password-input" placeholder="Masukkan Konfirmasi Password..." type="password" v-model="edit_password_confirm"></b-form-input>
                    </b-form-group>
                    <hr />
                </div>
                <div class="col-md-12">
                    <b-form-group class="mb-3" label="Nama" label-for="formrow-nama-input">
                        <b-form-input id="formrow-nama-input" placeholder="Masukkan Nama..." type="text" v-model="edit_name"></b-form-input>
                    </b-form-group>
                    <hr />
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="text-start">&nbsp;</div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-end">
                            <b-button type="reset" variant="danger"><i class="fa fa-redo-alt"></i> Reset</b-button>
                            &nbsp;
                            <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
    </b-modal>

    <b-modal v-model="form_photo" id="modal" title="Ubah Foto Profil Akun" title-class="font-18" hide-footer>
        <b-form class="p-2" @submit.prevent="StoreDataPhoto">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="axiosCatchErrorPhoto">
                        <div class="alert alert-danger" v-if="typeof axiosCatchErrorPhoto === 'string' || axiosCatchErrorPhoto instanceof String">
                            {{ axiosCatchErrorPhoto }}
                        </div>
                        <div class="alert alert-danger" role="alert" v-else>
                            <div v-for="(errorArray, idx) in axiosCatchErrorPhoto" :key="idx">
                                <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                    <span class="text-danger">{{ allErrors[0] }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-3">
                        <label for="formPhoto" class="form-label">Pilih Foto</label>
                        <input class="form-control" type="file" id="formPhoto" v-on:change="inputFilePhoto()" />
                        <div class="respond-input-file float-left" id="uploadLoading"></div>
                        <small class="float-right" style="color: red">*) Max File 2 MB</small>
                    </div>
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">&nbsp;</div>
                <div class="col-md-6">
                    <div class="text-end">
                        <b-button type="reset" variant="danger"><i class="fa fa-redo-alt"></i> Reset</b-button>
                        &nbsp;
                        <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                    </div>
                </div>
            </div>
        </b-form>
    </b-modal>
</template>
